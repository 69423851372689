import React, { Fragment, useEffect } from "react";
import CheckoutSteps from "../Cart/CheckoutSteps";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../Home/layout/MetaData";
import { toast } from "react-toastify";
import axios from "axios";
import "./payment.css";
import { createOrder, clearErrors } from "../../actions/orderAction";
import { maile } from "../../actions/userAction";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);

  const mailes = {
    email: user.email,
    totalprice: orderInfo.totalPrice,
  };

  const address = {
    line1: shippingInfo.address,
    city: shippingInfo.city,
    state: shippingInfo.state,
    postal_code: shippingInfo.pinCode,
    country: shippingInfo.country,
  };

  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: orderInfo.subtotal,
    taxPrice: orderInfo.tax,
    shippingPrice: orderInfo.shippingCharges,
    totalPrice: orderInfo.totalPrice,
  };

  const checkoutHandler = async (data) => {
    try {
      const { data: { key } } = await axios.get("/api/v1/rezpay");
      const options = {
        key,
        amount: data.amount * 100,
        currency: "INR",
        name: user.name,
        order_id: data.id,
        handler: function (response) {
          axios.post(`/api/v1/paymentverification`, response).then((res) => {
            if (res.data.b === "succeeded") {
              order.paymentInfo = {
                razorpay_order_id: res.data.razorpay_order_ids,
                razorpay_payment_id: res.data.razorpay_payment_ids,
                razorpay_signature: res.data.razorpay_signatures,
                status: res.data.b,
              };

              dispatch(createOrder(order));
              dispatch(maile(mailes));
              navigate('/success');
            }
          }).catch((err) => {
            console.error(err);
          });
        },
        prefill: {
          name: user.name,
          email: user.email,
          contact: user.phoneNumber,
        },
        notes: {
          address: address,
        },
        theme: {
          color: "#de0909",
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckout = async () => {
    try {
      const response = await axios.post('/api/v1/checkout', {
        amount: orderInfo.totalPrice,
        // Add any other required parameters
      });

      if (response.data.success) {
        console.log('Order created:', response.data.order);
        checkoutHandler(response.data.order);
      } else {
        console.error('Error creating order');
      }
    } catch (error) {
      console.error('An error occurred during the checkout:', error);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, error]);

  return (
    <Fragment>
      <MetaData title="Payment" />
      <CheckoutSteps activeStep={2} />
      <div className="paymentContainer">
        <input
          type="submit"
          value={`Pay - ₹${orderInfo && orderInfo.totalPrice}`}
          onClick={handleCheckout}
          className="paymentFormBtn"
        />
	 <p className="poweredByRazorpay">Powered by Razorpay</p>
      </div>
    </Fragment>
  );
};

export default Payment;
