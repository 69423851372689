export const REFERRAL_AMOUNT_REQUEST="REFERRAL_AMOUNT_REQUEST";
export const REFERRAL_AMOUNT_SUCCESS="REFERRAL_AMOUNT_SUCCESS";
export const REFERRAL_AMOUNT_FAIL="REFERRAL_AMOUNT_FAIL";



export const FETCH_HISTORY_REQUEST = 'FETCH_HISTORY_REQUEST';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_FAIL = 'FETCH_HISTORY_FAIL';


export const FETCH_TREE_SUCCESS = 'FETCH_TREE_SUCCESS';
export const FETCH_TREE_FAILURE = 'FETCH_TREE_FAILURE';