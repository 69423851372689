import React from "react";
import "./sidebar.css";

import { Link } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import HistoryIcon from '@mui/icons-material/History';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <Link to="/">
      
      </Link>
      <Link to={'/referralmainpage'}>
        <p>
          <DashboardIcon /> Refeerer page
        </p>
      </Link>
     
      <Link to={'/referral/map'} >
        <p>
          <ListAltIcon />
          map
        </p>
      </Link>
      <Link to={"/historyincome"} >
        <p>
          <PeopleIcon /> Income
        </p>
      </Link>
      <Link to={"/history"} >
        <p>
          <HistoryIcon /> History
        </p>
      </Link>
    </div>
  );
};

export default Sidebar;
