import React from "react";
import "./aboutSection.css";
import NorthIcon from '@mui/icons-material/North';

const About = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };
  return (
    // <div className="aboutSection">
      <div>
    <button className="scroll-to-top-button" onClick={scrollToTop}>
     <NorthIcon/>
    </button>
  
     
          <div className="about">
                        <h1> Welcome to Cameron: Elevating Comfort and Style in Men's Underwear</h1>
<p>
 Discover the epitome of comfort and style with Cameron, your go-to destination for premium men's underwear. We take pride in crafting exceptional garments that redefine the essence of everyday wear, ensuring you experience unparalleled comfort without compromising on style. </p>
<h2>

Explore Our Underwear Collections:
</h2><p>

<h2>
  1. Supreme Comfort, Impeccable Style:
  </h2>
Indulge in the luxury of comfort with our thoughtfully designed underwear collections. From breathable boxer briefs to classic briefs, each piece is meticulously crafted to provide the perfect fit and unparalleled softness. Cameron underwear is more than just a necessity; it's a statement of confidence and sophistication.

</p>
<h2>

2. Quality Craftsmanship, Made to Last:
</h2><p>
  
At Cameron, we control every aspect of the manufacturing process. Our in-house manufacturing unit ensures that each pair of underwear meets the highest standards of quality. We use premium fabrics and cutting-edge techniques to create durable, long-lasting underwear that stands up to the rigors of daily wear.

</p>
<h2>
  
3. Timeless Designs for Every Man:
</h2><p>

Our designs cater to diverse tastes, offering a range of classic and contemporary styles. Whether you prefer understated elegance or bold statements, Cameron has the perfect pair for you. Elevate your everyday style with our timeless designs that blend seamlessly with your wardrobe.
</p>
<h2>
  
Why Choose Cameron?
</h2><h2>

1. Unmatched Comfort:
</h2><p>
  
Experience all-day comfort with Cameron's underwear. Our focus on ergonomic design and quality materials ensures a snug fit that moves with you, providing unparalleled comfort from morning to night.

</p>
<h2>

2. Direct from Our Manufacturing Unit:
</h2><p>
By eliminating intermediaries, we bring you premium-quality underwear at a price that reflects true value. Shop directly from our manufacturing unit to experience affordability without compromising on excellence.
  </p>
<h2>

3. Discreet Packaging and Fast Shipping:
</h2>
<p>

Your comfort is our priority, from the moment you place your order to the time it arrives at your doorstep. Benefit from discreet packaging and fast shipping, ensuring a seamless shopping experience.
</p><h2>

Connect with Cameron:
</h2>
<p>

Follow us on social media for the latest updates, exclusive promotions, and behind-the-scenes glimpses of our manufacturing process. Join the Cameron community and share your comfort stories using #CameronComfort for a chance to be featured.
</p>
<p>

Elevate your comfort and style with Cameron - Where Every Pair Matters.
</p>
<p > 

D.N hosiery<br/>
47 Bhagwatikrupa soc opp d-mart bapunager Ahmedabad<br/>
Ahmedabad , Gujarat 380024<br/>
India<br/>
Phone:<a href='tel:+919624099204' className='email'>
 +91 9624099204<br/>
</a>
<a href='mailto:dnhosiery1919@gmail.com' className='email'> 
 dnhosiery1919@gmail.com<br/>
</a>
</p>



          </div>
      
    </div>
    
  );
};

export default About;
