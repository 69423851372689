import React, { useState } from 'react'
import {Referral} from '../../../../actions/userAction'
import { useDispatch } from 'react-redux';
import "./referralmainpage.css"
import "./refemap.css"
import Refersidebar from './refersidebar'
const ReferralMainpage = () => {

    const dispatch=useDispatch();
   const [Referrals , setReferral]= useState("");
   const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(Referral(Referrals));
  };

  return (
    <div className='mapp'>
      <Refersidebar/>
        <div className='mappcontener'>
        <form className="loginForm"  onSubmit={loginSubmit}>

        <input className='logininput'
         type="text"
         placeholder="Referral"
         required
         value={Referrals}
         onChange={(e) => setReferral(e.target.value)}
         
         />
        <input type="submit" value="Enter" className="btn" />
         </form>
        </div>
      {/* <p>dghsfhs</p> */}
    </div>
 
  )
}

export default ReferralMainpage
