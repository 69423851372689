import React from "react";
import "./Contact.css";
// import { Button } from "@mui/material/Button";
import Button from '@mui/material/Button';

const Contact = () => {
  return (
    <div className="contactContainer">

        <p className="mailBtn" > 

 Brand Name: D.N hosiery<br/>
address :47 Bhagwatikrupa soc opp d-mart bapunager Ahmedabad<br/>
Ahmedabad , Gujarat 380024<br/>
India<br/>
Phone:<a href='tel:+919624099204' className='email'>
 +91 9624099204<br/>
</a>
<a href='mailto:dnhosiery1919@gmail.com' className='email'> 
 dnhosiery1919@gmail.com<br/>
</a>
</p>
    </div>
  );
};

export default Contact;
