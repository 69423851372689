import React from 'react';
import { useSelector } from 'react-redux';
import {  Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const {user} = useSelector(
    (state) => state.user
  );
  // const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  return user?.role==='admin' ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
