import React from "react";
// import playStore from "../../../images/playstore.png";
// import appStore from "../../../images/Appstore.png";
import "./Footer.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Use smooth scrolling for a nicer effect
    });
  }; 
  const sendMessage = () => {
    const phoneNumber = '9624099204'; // Replace with the recipient's phone number
    const message = 'Hello'; // Replace with your message

    // Construct the WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Open WhatsApp in a new tab or window
    window.open(whatsappUrl);
  };
  return (
    <footer id="footer" >
      <div className="leftFooter">
        <Link to={"/term"}  onClick={scrollToTop} className="termand" >Terms and Conditions</Link>
        <Link to={'/PrivacyPolicy'} onClick={scrollToTop} className="termand">Privacy Policy</Link>
        <Link to={'/RefundPolicy'} onClick={scrollToTop} className="termand">Refund Policy</Link>
        <Link to={'/ShippingPolicy'} onClick={scrollToTop} className="termand">Shipping Policy</Link>
        
   
        <Link to={"/"} onClick={scrollToTop} className="home">Home</Link>
        {/* <p>Download App for Android and IOS mobile phone</p> */}
        {/* <img src={playStore} alt="playstore" />
        <img src={appStore} alt="Appstore" /> */}
      </div>
      <button className="whatsapp-button" onClick={sendMessage}>
     <WhatsAppIcon/>
    </button>
      <div className="midFooter">
        <h1>Cameron<sup>&#174;</sup></h1>
        <p>High Quality is our first priority</p>

        <p>Copyrights 2004  &copy; D.N HOSIERY</p>
      </div>
      <div className="rightFooter">
        <h4>Follow Us</h4>
        <a href="tel: +91 9624099204">CONTACT US:9624099204</a>
        <a href="https://www.facebook.com/d.n.hosiery.2023?mibextid=hIlR13">Facebook</a>
      </div>
  
    </footer>
  );
};

export default Footer;
