import React from 'react'
import './sp.css'
import NorthIcon from '@mui/icons-material/North';
const Shipping_Policy = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Use smooth scrolling for a nicer effect
    });
  }; 
  return (
    <div className='sp'>
      <div>
    <button className="scroll-to-top-button" onClick={scrollToTop}>
     <NorthIcon/>
    </button>
    </div>
     <h4>
          IS SHIPPING FREE?
        </h4>
<p>

Yes, Shipping is FREE all over India.
</p>
 <h4>

WHEN WILL I RECEIVE MY ORDER?
 </h4>
<p>
Orders are shipped out directly from any of our warehouses and they will do everything they can to get you your order as fast as they can! Due to the popularity of our offers, please allow an estimated 3-7 days for your order to arrive.
</p>

 <h4>

CAN I TRACK MY ORDER?
 </h4>

<p>
YES, you will receive the AWB number within 24 hours once you place an order. You can track your order on our Tracking Page with the help of an AWB number. If still, you face an issue in tracking your orders, feel free to call or WhatsApp us at 9624099204.
</p>
<h4>

WHY IS MY ORDER BEING SHIPPED IN DIFFERENT PACKAGES?
</h4>
<p>
If you have a multi-item order, each item may be shipped from a different warehouse, depending on which one has them available the fastest. Alternatively, if an item is popular and on a bit of a backorder, we might ship your items at different times, in different packages, to prevent holding up your order and to get it to you as fast as possible! 
</p>


<h4>

WHAT HAPPENS IF MY ORDER GETS STUCK OR LOST IN THE MAIL?
</h4>
<p>
All of our orders are sent with insured shipping and handling. If an order gets stuck somewhere, sent back, or even lost during the delivery process, we apologize! The postal service is out of our control. However, in cases like this, because the packages are insured, we will send you a new package with quicker shipping and full tracking, if possible. Please see our refund and return policy for when these might be applicable to shipping situations.
</p>

 <p>

For most serviceable pin codes, we try to deliver within 7 days. But due to unforeseen circumstances like weather, strikes, remote locations, stocking issues, or any other reason - it may take longer. You will, however, be able to track your package using a unique tracking link that we will email/SMS you after your order is sent to our delivery partner.
 </p>


    </div>
  )
}

export default Shipping_Policy
