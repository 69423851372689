import {
    PAYMENT_SUCCESS,
    PAYMENT_REQUEST,
    PAYMENT_FAIL,
    // PAYMENTSTATUS_REQUEST,
    // PAYMENTSTATUS_SUCCESS,
    // PAYMENTSTATUS_FAIL,

    CLEAR_ERRORS
} from '../constants/paymentConstants'

export const paymentReducer = (state = { payments: {} }, action) =>{
    switch(action.type){
        case   PAYMENT_REQUEST  :
            return {
                loading:false,
                isAuthenticated: true,
            }

        case PAYMENT_SUCCESS:
            return{
                ...state,
                loading: false,
                isAuthenticated: true,
                payment: action.payload,
            }
            case PAYMENT_FAIL:
                return {
                  ...state,
                  loading: false,
                  error: action.payload,
                };
          
              case CLEAR_ERRORS:
                return {
                  ...state,
                  error: null,
                };
          
              default:
                return state;
            }

    }

    // export const payment_status_DetailsReducer = (state = { payment_status: {} }, action) => {
    //   switch (action.type) {
    //     case PAYMENTSTATUS_REQUEST:
    //       return {
    //         ...state,
    //         // loading: true,
    //       };
    //     case PAYMENTSTATUS_SUCCESS:
    //       return {
    //         ...state,
    //         loading: false,
    //         payment_status: action.payload,
    //       };
    
    //     case PAYMENTSTATUS_FAIL:
    //       return {
    //         ...state,
    //         loading: false,
    //         error: action.payload,
    //       };
    
    //     case CLEAR_ERRORS:
    //       return {
    //         ...state,
    //         error: null,
    //       };
    
    //     default:
    //       return state;
    //   }
    // };
    