import React from 'react'
import { useEffect, useState ,Fragment} from "react";
import Loader  from "../Loader/Loader";
import {useDispatch ,useSelector} from "react-redux";
import {clearErrors , Referrallogin } from "../../../../actions/userAction";


import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import "./referral.css"

const Referral = () => {
  const dispatch = useDispatch();
  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
    const navigate=useNavigate();
  const [loginReferral, setLoginReferral] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(Referrallogin(loginReferral, loginPassword));
    navigate('/referralmainpage');
    
  };

  useEffect(() => {
    if (error) {
      toast.error(error)
      dispatch(clearErrors());
    }

    if (isAuthenticated) {
      navigate("/referralmainpage")
    }
    
  }, [dispatch, error, isAuthenticated, navigate]);


  return (
    <Fragment>{loading ? (<Loader/>) 
    :(
    <Fragment>
       <div className="LoginSignUpContainer">
            <div className="LoginSignUpBox">
     <form className="loginForm" action='./Reagister.js'  onSubmit={loginSubmit}>
                <div className="loginEmail">
                  <input
                    type="num"
                    placeholder="Referral"
                    required
                    value={loginReferral}
                    onChange={(e) => setLoginReferral(e.target.value)}
                  />
                </div>
                <div className="loginPassword">
                 
                  <input
                    type="password"
                    placeholder="Password"
                    required
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                  />
                </div>
                <input type="submit" value="Login" className="loginBtn" />
              </form>
              </div>
            </div>
    </Fragment>
    ) 
  }
      
  </Fragment>
  )
}

export default Referral
