import React, { useEffect } from "react";
import {  BrowserRouter, Route , Routes  } from "react-router-dom";

import store from "./store";
import { loadUser, loadReferral} from "./actions/userAction.js";


//pages
import Home from './components/Home/Home/Home.js'
import Contact from './components/Contact/Contact.js'
import About from './components/About/About.js'
import Login from './components/Home/User/LoginSignUp'
import Product from './components/Home/Product/products.js'
import ProductDetails from './components/Home/Product/productDetailes.js'

//User

import ForgotPassword from './components/Home/User/ForgotPassword'
import ResetPassword from './components/Home/User/ResetPassword.js'
import Profile from './components/Home/User/Profile.js'
import UpdatePassword from './components/Home/User/UpdatePassword.js'
import UpdateProfile from './components/Home/User/UpdateProfile.js'
import PrivateRoute  from './components/Route/protecterd.js'
import Admin from './components/Route/ProtectedRoute.js'
//card
import Payment from './components/Cart/pemtnts'
import Shipping from './components/Cart/Shipping'
import Cart from "./components/Cart/Cart"
import OrderSuccess from './components/Cart/OrderSuccess'
import ConfirmOrder from './components/Cart/ConfirmOrder.js'
//heder footer etc
import Footer from './components/Home/layout/Footer/Footer'
import NotFound from './components/Home/layout/Not Found/NotFound.js'
import Term from './components/Home/layout/Footer/TermsandConditions'
import PrivacyPolicy from './components/Home/layout/Footer/pp'
import RefundPolicy from './components/Home/layout/Footer/RefundPolicy.js'
import ShippingPolicy from './components/Home/layout/Footer/ShippingPolicy.js'
import Headersss from './components/Home/layout/Header/headerrrr'

//order
import OrderDetails from './components/Home/Order/OrderDetails.js'
import MyOrders from './components/Home/Order/MyOrders.js'

//private route


//admin
import OrderList from './components/Admin/OrderList.js'
import UpdateProduct from './components/Admin/UpdateProduct.js'
import UsersList from './components/Admin/UsersList'
import ProcessOrder from './components/Admin/ProcessOrder.js'
import ProductList from './components/Admin/ProductList.js'
import Reviews from './components/Admin/ProductReviews.js'
import NewProduct from './components/Admin/NewProduct.js'
import Dashboard from './components/Admin/Dashboard.js'
import UpdateUser from './components/Admin/UpdateUser.js'

//stripe
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//reffer
import History from './components/Home/layout/Referral/hister.js'
import Referrallogin from './components/Home/layout/Referral/Referral.js'
import Referralmain from './components/Home/layout/Referral/referralmainpage.js'
import Referralsidebar from './components/Home/layout/Referral/refersidebar.js'
import ReferMAp from './components/Home/layout/Referral/refemap.js'
import Historyincome from './components/Home/layout/Referral/histryincome.js'

function App() {

  useEffect(()=>{
    
    store.dispatch(loadReferral());
    store.dispatch(loadUser()); 
    
  },[]);

  window.addEventListener("contextmenu", (e) => e.preventDefault());

  return (
    <>
    <ToastContainer
         position="top-center"
          autoClose={2000}          
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"/>
    <BrowserRouter>
        <Headersss/>
        
        
    <Routes >
    

        <Route exact path="/" element={<Home/>} />
        
        <Route exact path="/products" element={<Product/>} />
        <Route exact path="/product/:id" element={<ProductDetails/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/account" element={<Profile/>} />
        
        <Route exact path="/historyincome" element={<Historyincome/>} />
       
       <Route
          exact
          path="/password/update"
          element={<PrivateRoute element={<UpdatePassword/>} />}
        />
        

         <Route exact path="/password/forgot" element={<ForgotPassword/>} />

         <Route exact path="/password/reset/:token" element={<ResetPassword/>} />

         <Route exact path="/login" element={<Login/>} />

         <Route exact path="/me/update" element={<PrivateRoute element={<UpdateProfile/>} />} />

         <Route exact path="/cart" element={<Cart/>} />

         <Route exact path="/shipping" element={<PrivateRoute element={<Shipping/>} />} />

         <Route exact path="/success" element={ <OrderSuccess/> } />

        <Route exact path="/orders" element={<PrivateRoute element={<MyOrders/>}/>} />
        
        <Route exact path="/order/confirm" element={<PrivateRoute element={<ConfirmOrder/>}/>} />

        <Route exact path="/order/:id" element={<PrivateRoute element={<OrderDetails/>}/>} />

         <Route exact path="/term" element={<Term/>} />
         <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
         <Route exact path="/RefundPolicy" element={<RefundPolicy/>}/>
         <Route exact path="/ShippingPolicy" element={<ShippingPolicy/>}/>

       <Route exact path="/process/payment" element={<PrivateRoute element={<Payment />}/>} />
       <Route exact path="/referral" element={<Referrallogin/>}/>
       <Route exact path="/referralmainpage" element={<Referralmain/>}/>
       <Route exact path="/referral/sidebar" element={<Referralsidebar/>}/>
       <Route exact path="/referral/map" element={<ReferMAp/>}/>
       <Route exact path="/history" element={<History/>}/>
       

      
        <Route
        exact
        path="/admin/dashboard"
        element={<Admin element={<Dashboard/>}/>}
      
      />
      

        <Route
          exact
          path="/admin/products"
          element={<Admin element={<ProductList/>}/>}
        />
    
        <Route
          exact
          path="/admin/product"
          element={<Admin element={<NewProduct/>}/>}
        />
       
        <Route
          exact
          path="/admin/product/:id"
          element={<Admin element={<UpdateProduct/>}/>}
        />
         
        <Route
          exact
          path="/admin/orders"
          element={<Admin element={<OrderList/>}/>}
        />
         
         <Route
          exact
          path="/admin/order/:id"
          element={<Admin element={<ProcessOrder/>}/>}
        />
      
        <Route
          exact
          path="/admin/reviews"
          element={<Admin element={<Reviews/>}/>}
        />
         
        <Route
          exact
          path="/admin/users"
          element={<Admin element={<UsersList/>}/>}
        />
         
         <Route
          exact
          path="/admin/user/:id"
          element={<Admin element={<UpdateUser/>}/>}
        />

         <Route
          element={
            window.location.pathname === "/process/payment" ? null : NotFound
          }
        /> 
    </Routes>
   
    <Footer/>
</BrowserRouter>
</>
  );
}

export default App;
