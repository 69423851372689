import React, { useState,useEffect } from 'react';
import Sidebar from './refersidebar'
import "./incomedistribute.css"
import {reqpayment} from '../../../../actions/userAction'
import { useDispatch,useSelector } from 'react-redux';
// import {amount} from '../../../../actions/refrralAction'
import {getReferralAmount} from '../../../../actions/refrralAction'
const MyForm = () => {
    const dispatch= useDispatch();

    const { amounts } = useSelector((state) => state.referral);
    useEffect(() => {
        dispatch(getReferralAmount());
    }, [dispatch])


  const [formData, setFormData] = useState({
    name: '',
    upi: '',
    phoneNUmber: '',
    accountNumber: '',
    ifsc: '',
    amount: '',
  });
  const { name,upi ,phoneNUmber,accountNumber, ifsc, amount } = formData;
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set("name", name);
    myForm.set("upi", upi);
    myForm.set("phoneNumber", Number(phoneNUmber)); // Update to use "phoneNumber" here
    myForm.set("accountNumber", accountNumber);
    myForm.set("ifsc", ifsc);
    myForm.set("amounts", amount);
    dispatch(reqpayment(myForm));
    console.log(formData);
  };
  


  return (
      <div className='mapp'>
        <Sidebar/>
        
        <div className='mappcontener'>
        {amounts &&  <div className='coin'>Referral Amount: ₹ {amounts} </div>  }  
              
            <form className='paymentreq' onSubmit={handleSubmit}>
      
      <div className='name'>
        <input type="text" name="name"  required  placeholder='name' value={formData.name} onChange={handleChange} />
      </div>

        <div className='upi'>
        <input type="text" name="upi"  required  placeholder='upi' value={formData.upi} onChange={handleChange} />
        </div>
      

        <div className='phonenumber'>
          <input type="number" name="phoneNUmber"  required  placeholder='phoneNumber' value={formData.phoneNUmber} onChange={handleChange} />
          </div>

        <div className='accountNumber'>
            <input type="number" name="accountNumber"  required  placeholder='accountNumber' value={formData.accountNumber} onChange={handleChange} />
            </div>
    

       <div className='ifsc'>
         <input type="text" name="ifsc"  required  placeholder='ifsc' value={formData.ifsc} onChange={handleChange} />
        </div>
  

     
       <div className='amount'>
         <input type="number" name="amount"  required  placeholder='amount' value={formData.amount} onChange={handleChange} />
        </div>
  
    

        <input type="submit" value="submit" className="signUpBtn" />
    </form>
    
    </div>
    </div>
  );
};

export default MyForm;
