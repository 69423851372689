import React ,{ useState}from 'react';
import "./headers.css"

import {logout} from '../../../../actions/userAction'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';


const NavBar = () => {
  const {  user,isAuthenticated } = useSelector(
    (state) => state.user
  );
    const navigate =useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dispatch = useDispatch();
  function logoutUser() {
    navigate('/')
    dispatch(logout());
    // alert.success("Logout Successfully");
  }
  const [menuOpen, setMenuOpen] = useState(false);
  
  return (
    
    <nav className="navbar">
       <Link className="title" to="/">Cameron<sup>&#174;</sup></Link>
      
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
       <ul className={menuOpen ? "open" : ""} >
         
        


        {isAuthenticated?   (
          
        <div className="dropdown">
          <li>

       <Link className="dropdown-toggle" onClick={toggleDropdown}>
           Menu
        </Link>
          </li>
       
       { isOpen && (
         <div className="dropdown-content">
           {
             user.role === "admin" &&(
               <Link onClick={()=>{setIsOpen(false)}} to='/admin/dashboard'>Dashboard</Link>
         )
         }
           <Link onClick={()=>{setIsOpen(false)}} to="/account">Profile</Link>  
           <Link onClick={()=>{setIsOpen(false)}} to="/cart">Cart</Link>
           <Link onClick={()=>{setIsOpen(false)}} to="/orders">Order</Link>
           
          
         </div>
       )}
      
     </div>
      ):(
        <div>
        <ul className={menuOpen ? "open" : ""}>
          <li>
            <Link to="/login" > Login</Link>
          </li>
          <li>
          <Link  to={"/referral"} >Referral</Link>
          </li>
        </ul>
        </div>
        )} 
        <li>
        <Link to="/products">Products</Link>
          </li><li>
        <Link onClick={logoutUser} to={'/'}> LogOut</Link>
          </li>
          <li>
         
          </li>
          <li>
        <Link to="/about">About</Link>
          </li>
        <li>
           <Link to="/Contact">Contact</Link>
        </li>
        </ul>
   
    </nav>
  );
};

export default NavBar;
