// PrivateRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import {  Navigate } from 'react-router-dom';

function PrivateRoute({ element }) {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  // const userRole = useSelector((state) => state.user.role);
  
    // const auth = useAuth();
    // return isAuthenticated ? <Route element={element} /> : <Navigate to="/login" />;
    return isAuthenticated ? element : <Navigate to="/login" />;
    // return isAuthenticated ? <>{element}</> : <Navigate to="/login" />;
  
}

export default PrivateRoute;
