import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchTree } from '../../../../actions/refrralAction'; 
import Refersidebar from './refersidebar'
import "./refemap.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const ReferMAp = () => {
  const dispatch = useDispatch();
  const { treeData, error } = useSelector((state) => state.reducers);
  useEffect(() => {
    dispatch(fetchTree());
  }, [dispatch]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!treeData) {
    return <div>Loading...</div>;
  }

  const TreeNode = ({ node, isRoot = false }) => {
    const getBackgroundColor = () => {
      // Add logic to set different colors based on the node's level
      const colors = ['red', 'green', 'blue', 'yellow', 'orange'];
      return colors[node.level % colors.length];
    };
  
    return (
      <div
        className={`tree-node ${isRoot ? 'root' : ''}`}
        style={{ backgroundColor: getBackgroundColor() }} 
      >
        {node.parent && <div className="connecting-line" />}
        <div className="node-content">
          <FontAwesomeIcon icon={faUser} />
          <p>User ID:{node.user}</p>
          <p>Level:{node.level}</p>
          <p>PhoneNumber:{node.phoneNumber}</p>
        </div>
        {node.children.length > 0 && (
          <div className="children-container">
            {node.children.map((child, index) => (
              <React.Fragment key={child.user}>
                <TreeNode node={child} />
                {index < node.children.length - 1 && (
                  <div className="connecting-line-horizontal" />
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    );
  };

  
  return (
    <div className='mapp'>
      <Refersidebar/>
        <div className='mappcontener'>
        <div className="mlm-tree">
        <h2>MLM Tree</h2>
        <div className="tree-container">
          <TreeNode node={treeData} isRoot />
        </div>
         </div>
         </div>
     {/* <p>dghsfhs</p> */}  
  </div> 
  )
}

export default ReferMAp
