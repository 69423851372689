import React,{useEffect,Fragment} from 'react'
import { clearErrors, getProduct } from "../../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import MetaData from '../layout/MetaData.js'
import Loader from '../layout/Loader/Loader.js'
import ProductCard from '../Home/productCard'
import "./Products.css"
// import Slider from "@mui/material/Slider"
import { toast } from "react-toastify";

const Product = () => {
    const dispatch = useDispatch();


    const {
        products,
        loading,
        error,
      } = useSelector((state) => state.products);
      // const [ratings, setRatings] = useState(0);

        useEffect(() => {
          if (error) {
            toast.error(error)
            dispatch(clearErrors());
          }
      
          dispatch(getProduct());
        }, [dispatch,  error]);
  return (
    <Fragment>
    {loading ? (
      <Loader />
    ) : (
      <Fragment>
        <MetaData title="ECOMMERCE" />
    
         <div className="banner">
         <a href="#container">
           <img className='imgs' alt='home' src='https://res.cloudinary.com/dujouvfwd/image/upload/v1703337620/2_qcg8w1.jpg'/>
          </a>
        </div>   

        <h2 className="homeHeading">Featured Products</h2>

        <div className="container" id="container">
          {products &&
            products.map((product) => (
              <ProductCard key={product._id} product={product} />
            ))}

        </div>
        {/* <div className="filterBox">
        <fieldset>
              <p component="legend">Ratings Above</p>
              <Slider
                value={ratings}
                onChange={(e, newRating) => {
                  setRatings(newRating);
                }}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                min={0}
                max={5}
              />
            </fieldset>
        </div> */}
      </Fragment>
    )}
  </Fragment>
  )
}

export default Product
