import React from 'react'
import './rp.css'
import NorthIcon from '@mui/icons-material/North';
const Refund_Policy = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Use smooth scrolling for a nicer effect
    });
  };  
  return (
    
    <div className='rp'>
 <div>
    <button className="scroll-to-top-button" onClick={scrollToTop}>
     <NorthIcon/>
    </button>
    </div>
    <p>

      In order to process a return or an exchange, please contact us for a return authorization.
    </p>
    <p>
We accept returns and exchanges within 10 DAYS of delivery. The returned items must be received in new, unused condition.
    </p>
    <h4>

RETURNS & EXCHANGES 
    </h4>
        <p>

We offer easy returns & exchanges on all orders. To return an item, please contact us and we will generate a return authorization for you. Returns are only accepted for items purchased directly from our website. The item(s) must be unused and in original packaging.
        </p>
        <p>

To complete an exchange, place your return/replace request, once the request is placed we will arrange a reverse pick up for the product and issue a replacement with the correct product once the damaged product is picked up by our logistics partner. This entire process should take around 2-3 working days.
        </p>
<h4>
REFUNDS</h4> 
<p>

Once we receive the return order, it will take 3-5 business days for a refund to appear on your statement. If 3-5 business days have passed and you still don’t see a refund, please feel free to contact us at dnhosiery1919@gmail.com or call us at 9624099204
</p>
<h4>

INVALID REASONS
</h4>
<p>
Buyer no longer wants the items - this is the most common example which we cannot honor if we want to continue offering great value at Shopgeeks. The buyer should make sure he or she wants to buy the items before submitting an order, not after. After an order is submitted, the buyer enters into a legally binding contract with the seller to purchase all items in that order.
</p>
<p>
The buyer should be confident that he or she is ready to pay the asking prices before submitting an order. After an order is submitted, the buyer enters into a legally binding contract with the seller to purchase all items in that order.

</p>
<h4>
    
CANCELLATIONS
    </h4>
    <p>
In the event you wish to cancel your order, please contact us. If the order has already shipped, we would be unable to stop the package.
        </p> 
 

    </div>
  )
}

export default Refund_Policy
