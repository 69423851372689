import React ,{Fragment,useEffect,useState}from 'react'
import Loader from '../layout/Loader/Loader'
import { useSelector, useDispatch } from "react-redux";
import {addItemsToCart} from '../../../actions/cartAction'
import { clearErrors,
    getProductDetails,newReview} from '../../../actions/productAction'
import MetaData from '../layout/MetaData'
import Carousel from "react-material-ui-carousel";
import Rating  from "react-rating-stars-component";
import StarRatings from 'react-star-ratings'
import "./ProductDetails.css"
import { useNavigate, useParams } from 'react-router-dom';
import ReviewCard from"./ReviewCard"


import {  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,} from '@mui/material';

import {NEW_REVIEW_RESET} from '../../../constants/productConstants'
import { toast } from 'react-toastify';


const ProductDetails = () => {
   
   const {id}= useParams();
  const navigate =useNavigate();
  //  console.log(match.params.id);

  const dispatch = useDispatch();


   const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );
  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );

  // console.log(product)
  const options = {
    size: "large",
    value: product.ratings,
    readOnly: true,
    precision: 0.5,
  };
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);  
  const [comment, setComment] = useState("");
  const [starRating, setStarRating] = useState(0);

  const handleRatingChange = rating => {
    setStarRating(rating);
  };

  const reviewSubmitHandler = () => {
    const myForm = new FormData();

    myForm.set("rating", starRating);
    myForm.set("comment", comment);
    myForm.set("productId",id);
    
    dispatch(newReview(myForm));
    
    setOpen(false);
  };



   const increaseQuantity = () => {
    if (product.Stock <= quantity) return;
    const qty = quantity + 1;
    toast.success('add this')
    setQuantity(qty);
  };

  const decreaseQuantity = () => {
    if (1 >= quantity) return;

    const qty = quantity - 1;
    toast.success('remove this')
    setQuantity(qty);
  };

  const addToCartHandler = () => {
    dispatch(addItemsToCart(id, quantity));
    toast.success("Item Added To Cart");
  };
  const Carttodireact=()=>{
    navigate('/cart')
    toast.success("move to card")
  }
  const submitReviewToggle = () => {
    open ? setOpen(false) : setOpen(true);
  };


    useEffect(()=>{
      if (error) {
        toast.success(error);
        dispatch(clearErrors());
      }
  
      if (reviewError) {
        toast.success(reviewError);
        dispatch(clearErrors());
      }
  
      if (success) {
        toast.success("Review Submitted Successfully");
        dispatch({ type: NEW_REVIEW_RESET });
      }
       dispatch(getProductDetails(id));

    },[dispatch,error,id,reviewError, success])
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={`${product.name} -- ECOMMERCE`} />
          <div className="ProductDetails">
            <div>
              <Carousel>
                {product.images &&
                  product.images.map((item, i) => (
                    <img
                      className="CarouselImage"
                      key={i}
                      src={item.url}
                      alt={`${i} Slide`}
                    />
                  ))}
              </Carousel>
            </div>

            <div>
              <div className="detailsBlock-1">
                <h2>{product.name}</h2>
                <p>Product # {product._id}</p>
              </div>
              <div className="detailsBlock-2">
                <Rating  {...options} />
                <span className="detailsBlock-2-span">
                  {" "}
                  ({product.numOfReviews} Reviews)
                </span>
              </div>
              <div className="detailsBlock-3">
                <h1>{`₹${product.price}`}</h1>
                <div className="detailsBlock-3-1">
                  <div className="detailsBlock-3-1-1">
                    
                    <button onClick={decreaseQuantity}>-</button>
                    <input readOnly type="number" value={quantity} />
                    <button onClick={increaseQuantity}>+</button>
                  </div>
                  <button className='button'
                    disabled={product.Stock < 1 ? true : false}
                    onClick={addToCartHandler}
                    >
                    Add to Cart
                  </button>
                    <button className='button' onClick={Carttodireact}>Cart</button>
                  
                </div>

                <p>
                  Status:
                  <b className={product.Stock < 1 ? "redColor" : "greenColor"}>
                    {product.Stock < 1 ? "OutOfStock" : "InStock"}
                  </b>
                </p>
              </div>

              <div className="detailsBlock-4">
                Description : <p>{product.description}</p>
              </div>



            <button onClick={submitReviewToggle} className="submitReview">
              Submit Review
            </button>
          </div>
        </div>
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={submitReviewToggle}
          >
            <DialogTitle>Submit Review</DialogTitle>
            <DialogContent className="submitDialog">
              {/* <Rating
                onChange={(e) => setRating(e.target.value)}
                value={rating}
                size="large"
              /> */}
                 <StarRatings
        rating={starRating}
        starRatedColor="gold"
        changeRating={handleRatingChange}
        numberOfStars={5}
        name="rating"
        starDimension="20px"  // Set the size of the stars
        starSpacing="2px" 
      />
              <textarea
                className="submitDialogTextArea"
                cols="30"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </DialogContent>
            <DialogActions>
              <Button onClick={submitReviewToggle} color="secondary">
                Cancel
              </Button>
              <Button onClick={reviewSubmitHandler} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {product.reviews && product.reviews[0] ? (
            <div className="reviews">
              {product.reviews &&
                product.reviews.map((review) => (
                  <ReviewCard key={review._id} review={review} />
                ))}
            </div>
          ) : (
            <p className="noReviews">No Reviews Yet</p>
          )}
        
        </Fragment>
      )}
    </Fragment>
  )
}

export default ProductDetails

 