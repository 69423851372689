import {
REFERRAL_AMOUNT_REQUEST,
REFERRAL_AMOUNT_SUCCESS,
REFERRAL_AMOUNT_FAIL,
FETCH_HISTORY_REQUEST,
  FETCH_HISTORY_SUCCESS,
  FETCH_HISTORY_FAIL,FETCH_TREE_SUCCESS, FETCH_TREE_FAILURE} from '../constants/referralConstants'

const initialState = {
    loading: false,
    amounts: null,
    error: null,
  };
  
  export const referralReducer = (state = initialState, action) => {
    switch (action.type) {
      case REFERRAL_AMOUNT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case REFERRAL_AMOUNT_SUCCESS:
        return {
          ...state,
          loading: false,
          amounts: action.payload,
          error: null,
        };
      case REFERRAL_AMOUNT_FAIL:
        return {
          ...state,
          loading: false,
          amounts: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  const initialStates = {
    loading: false,
    history: [],
    error: null,
  };
  
  export const historyReducer = (state = initialStates, action) => {
    switch (action.type) {
      case FETCH_HISTORY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          history: action.payload,
          error: null,
        };
      case FETCH_HISTORY_FAIL:
        return {
          ...state,
          loading: false,
          history: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  

  

  const initialStatea = {
    treeData: null,
    error: null,
  };
  
  export const reducers = (state = initialStatea, action) => {
    switch (action.type) {
      case FETCH_TREE_SUCCESS:
        return {
          ...state,
          treeData: action.payload,
          error: null,
        };
      case FETCH_TREE_FAILURE:
        return {
          ...state,
          treeData: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };