import {REFERRAL_AMOUNT_REQUEST
,REFERRAL_AMOUNT_SUCCESS,
REFERRAL_AMOUNT_FAIL,
FETCH_HISTORY_REQUEST,
FETCH_HISTORY_SUCCESS,
FETCH_HISTORY_FAIL,
FETCH_TREE_SUCCESS, FETCH_TREE_FAILURE
} from '../constants/referralConstants'
import axios from "axios";
import { toast } from 'react-toastify';

export const getReferralAmount = () => async (dispatch) => {
  try {
    dispatch({ type: REFERRAL_AMOUNT_REQUEST });

    const { data } = await axios.get('/api/v1/amount');

    dispatch({
      type: REFERRAL_AMOUNT_SUCCESS,
      payload: data.b,
    });
  } catch (error) {
    toast.error(error.response.data.message);

    dispatch({
      type: REFERRAL_AMOUNT_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const fetchHistory = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_HISTORY_REQUEST });

    const { data } = await axios.get('/api/v1/history');

    dispatch({
      type: FETCH_HISTORY_SUCCESS,
      payload: data.a,
    });
  } catch (error) {
    toast.error(error.response.data.message);

    dispatch({
      type: FETCH_HISTORY_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const fetchTreeSuccess = (treeData) => ({
  type: FETCH_TREE_SUCCESS,
  payload: treeData,
});

export const fetchTreeFailure = (error) => ({
  type: FETCH_TREE_FAILURE,
  payload: error,
});

export const fetchTree = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/level');
    dispatch(fetchTreeSuccess(response.data.a));
  } catch (error) {
    dispatch(fetchTreeFailure(error.message));
  }
};


