import React,{useEffect,Fragment} from 'react'
import { clearErrors, getProduct } from "../../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import MetaData from '../layout/MetaData.js'
import Loader from '../layout/Loader/Loader.js'
import ProductCard from '../Home/productCard'
import { toast } from 'react-toastify';

const Home = () => {
    const dispatch = useDispatch();
    const imageUrl = 'https://res.cloudinary.com/dujouvfwd/image/upload/v1703337620/2_qcg8w1.jpg';
    const {
        products,
        loading,
        error,
      } = useSelector((state) => state.products);

      
        useEffect(() => {
          if (error) {
            toast.error(error)
            dispatch(clearErrors());
          }
          
          dispatch(getProduct());
        }, [dispatch,  error]);
  return (
    <Fragment>
    {loading ? (
      <Loader />
    ) : (
      <Fragment>
        <MetaData title="D.N.HOSIERY" />

        <div className="banner">
          
      <a href="#container"> 
            <img src={imageUrl} className='imgs' alt="Home"  />
          </a>
        </div>

        <h2 className="homeHeading">Products</h2>

        <div className="container" id="container">
          {products &&
            products.map((product) => (
              <ProductCard key={product._id} product={product} />
            ))}
        </div>
      </Fragment>
    )}
  </Fragment>
  )
}

export default Home
