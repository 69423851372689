import React ,{useEffect}from 'react'
import Slider from './refersidebar'
import "./history.css"
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import {fetchHistory} from '../../../../actions/refrralAction'
const History = () => {
    const dispatch = useDispatch();
    const {  history } = useSelector((state) => state.history);
  
    useEffect(() => {
      dispatch(fetchHistory());
    }, [dispatch]);
  
    const columns = [
      { field: 'name', headerName: 'Name', width: 150 },
      // { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
      { field: 'amount', headerName: 'Amount', width: 150 },
      { field: 'process', headerName: 'Process', width: 150 },
    ];
  return (
    <div className='mapp'>
    <Slider/>  
    <div className='mappcontener'>
    <div className='history' >
      {history && (
        <DataGrid
          rows={history}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
        //   checkboxSelection
          disableSelectionOnClick
          getRowId={(row) => row._id} 
          autoHeight
        />
      )}
    </div>
        </div>
    </div>
  )
}

export default History
